@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus-visible:not(input, textarea, select) {
    outline: 1px dashed currentColor !important;
}

body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    /* padding: 0 0 45px; */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.18;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-moz-placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}

:-ms-input-placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}

::placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}
.dark-placeholder input::-moz-placeholder, .dark-placeholder textarea::-moz-placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}
.dark-placeholder input:-ms-input-placeholder, .dark-placeholder textarea:-ms-input-placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}
.dark-placeholder input::placeholder,
.dark-placeholder textarea::placeholder {
    color: #6f6f6f !important;
    opacity: 1 !important;
}

button,
input,
select,
textarea {
    font-family: "Roboto", sans-serif;
}

a,
button {
    cursor: pointer;
}

a[rel^="noopener noreferrer"] {
    word-break: break-word;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

p + p {
    margin: 24px 0 0;
}

.entryText .dataBlock {
    display: block;
    margin: 24px 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    padding: 16px 24px;
    font-size: 14px;
    word-break: break-all;
}

.entryText > *:first-child {
    margin-top: 0 !important;
}

.entryText hr {
    margin: 24px 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 1px;
    outline: 0 none;
    border: 0 none;
}

.entryText img {
    display: block;
    max-width: 100% !important;
    height: auto !important;
    margin: 24px auto;
}

.entryText h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h3,
.entryText h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.loading {
    position: fixed;
    z-index: 9000099;
    height: 70px;
    width: 70px;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}
/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: "";
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    outline: none;
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide-inner {
    padding: 0 15px;
    outline: none;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 16px 0 70px;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: rgba(0, 0, 0, 0.1);
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    background: rgba(0, 0, 0, 0.4);
    outline: none;
}
.slick-dots li.slick-active button {
    background: rgba(0, 0, 0, 0.87);
}
.fr-emoticon {
    font-weight: 400;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol",
        "Android Emoji", EmojiSymbols;
    display: inline;
    line-height: 0;
}
.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
}

.materialUISelect {
    display: flex;
}
.materialUISelect > div {
    width: 100%;
}
.materialUISelect > div:after {
    border-bottom: 2px solid #7ce6d8;
}
.materialUISelect > div:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.materialUISelect > div:hover:before {
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
    border-bottom-width: 1px !important;
}
.materialUISelect > div[class="MuiInput-underline-"] {
    border-bottom: 2px solid #e0112b !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk,
#onetrust-consent-sdk #onetrust-banner-sdk:focus-visible {
    outline: 0 none !important;
}

.calendar-group {
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: column;
    width: 100%;
}

.calendar-group > div {
    flex: 1 1;
    flex-shrink: 0;
}

.calendar-transition-enter-left {
    opacity: 0;
    transform: translateX(100%);
}

.calendar-transition-enter-left-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-enter-right {
    opacity: 0;
    transform: translateX(-100%);
}

.calendar-transition-enter-right-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-exit-left {
    opacity: 1;
    transform: translateX(0%);
}

.calendar-transition-exit-left-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-exit-right {
    opacity: 1;
    transform: translateX(0%);
}

.calendar-transition-exit-right-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

