.calendar-group {
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: column;
    width: 100%;
}

.calendar-group > div {
    flex: 1;
    flex-shrink: 0;
}

.calendar-transition-enter-left {
    opacity: 0;
    transform: translateX(100%);
}

.calendar-transition-enter-left-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-enter-right {
    opacity: 0;
    transform: translateX(-100%);
}

.calendar-transition-enter-right-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-exit-left {
    opacity: 1;
    transform: translateX(0%);
}

.calendar-transition-exit-left-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}

.calendar-transition-exit-right {
    opacity: 1;
    transform: translateX(0%);
}

.calendar-transition-exit-right-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}
